import * as React from "react";
import Layout from "../components/Layout/layout";

const Notfound = () => {
  return (
    <>
      <Layout>
        <div>
          <h1>404</h1>
          <h3>Page Not Found</h3>
        </div>
      </Layout>
    </>
  );
};

export default Notfound;
